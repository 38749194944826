import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Navigate, Routes} from 'react-router-dom'
import * as auth from './redux/AuthRedux'

export function Logout() {
  const dispatch = useDispatch()
  useEffect(() => {
    // dispatch(auth.actions.logout())
    localStorage.clear();
    sessionStorage.clear();
    document.location.reload();
  }, [dispatch])

  return (
    // <Routes>
    //   <Navigate to='/auth/login' />
    // </Routes>
    <div>
      LOGGING OUT ...
    </div>
  )
}
