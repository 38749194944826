/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {httpClient} from 'appUtility/Api'

type Props = {
  className: string
}

const ListsWidget3: React.FC<Props> = ({className}) => {
  // const newsData = [
  //   {news: "Routine campaigns this week", time: "2 days ago." , priority: "1"},
  //   {news: "Exercises and Childhood Obesity", time: "1 day ago." , priority: "0"},
  //   {news: "Lawrence Township manager talks about the state of development in town", time: "2 days ago." , priority: "1"},
  //   {news: "Redevelopment kicks into high gear on Parkway Ave in Ewing", time: "3 days ago." , priority: "1"},
  //   {news: "Catalina Empanadas bring a taste of Chile to area farmers markets", time: "2 days ago." , priority: "0"},
  //   {news: "Turtle Beans invites Bordentown to slow down, smell the coffee", time: "2 days ago." , priority: "0"},
  // ]
  const loginData = JSON.parse(sessionStorage.getItem('auth') || '{}')
  const orgMember = loginData && loginData?.member?.orgLoginID ? true : false
  const workplaceID = loginData && loginData?.member?.workplaceID

  const [allNews, setAllNews] = useState<any[]>([])

  useEffect(() => {
    getNews()
  }, [])

  const getNews = () => {
    httpClient
      .get(
        orgMember
          ? '/organization/news'
          : workplaceID != null
          ? '/workplace-dashboard/news'
          : 'news'
      )
      .then(({data}) => {
        if (data.success) {
          setAllNews(data.data)
        }
      })
  }

  // console.log('all news is', allNews);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bolder text-dark'>News</h3>
        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
          </button>
          <Dropdown1 />
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        {allNews.length > 0 &&
          allNews.map(
            (news, index) =>
              index < 6 && (
                <div className='d-flex align-items-center mb-8'>
                  {/* <span className='bullet bullet-vertical h-40px bg-success'></span> */}
                  <div className='flex-grow-1'>
                    <a href='#' className='text-gray-800 text-hover-primary fw-bolder fs-6'>
                      {news.newsTitle}
                    </a>
                    <span className='text-muted fw-bold d-block'>{news.newsDate}</span>
                  </div>
                  {news.highPriority === 'Priority' ? (
                    <span className='badge badge-light-success fs-8 fw-bolder'>High Priority</span>
                  ) : (
                    <span className='badge badge-light fs-8 fw-bolder'>Low Priority</span>
                  )}
                </div>
              )
          )}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget3}
