import {lazy, FC, Suspense, Children} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {Logout} from '../modules/auth'
import {VerifyOrganization, VerifyWorkplace} from '../modules/auth'
import { Charitypage } from '../modules/charity_cms/Charitypage'
// import Customers from '../modules/customers'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const CustomersPage = lazy(() => import('../modules/customers'))
  const CategoryPage = lazy(() => import('../modules/categories'))
  const OrganizationPage = lazy(() => import('../modules/organization'))
  const CausePage = lazy(() => import('../modules/causes'))

  const MemberPage = lazy(() => import('../modules/members'))
  const AvatarPage = lazy(() => import('../modules/avatars'))
  const LoyalityPage = lazy(() => import('../modules/loyality_rule'))
  const StatusPage = lazy(() => import('../modules/member_level'))
  const DonationPage = lazy(() => import('../modules/donations'))
  const CommunicationTypesPage = lazy(() => import('../modules/communication_types'))
  const CommunicationPreferencePage = lazy(() => import('../modules/communication_preferences'))
  const CountryPage = lazy(() => import('../modules/country'))
  const StatePage = lazy(() => import('../modules/state'))
  const CurrencyPage = lazy(() => import('../modules/currency'))
  const TimezonePage = lazy(() => import('../modules/timezones'))
  const NewsCategoryPage = lazy(() => import('../modules/news_category'))
  const HowItWorks = lazy(() => import('../modules/HowItWorks'))
  const InvestInCauses = lazy(() => import('../modules/invest_in_causes'));
  const OurTeam = lazy(() => import('../modules/our_team'));
  
  const CMSPages = lazy(() => import('../modules/cms_pages'));
  
  const Notifications =  lazy(() => import('../modules/notifications'));
  // const Pages =  lazy(() => import('../modules/pages'));

  const NewsPage = lazy(() => import('../modules/news'))
  const TagsPage = lazy(() => import('../modules/tags'))

  const UserPage = lazy(() => import('../modules/users'))
  const OrgProfile = lazy(() => import('../modules/organization_profile'))

  const ChangePasswordPage = lazy(() => import('../modules/change_password'))

  const Homepage = lazy(() => import('../modules/homepage'))
  const CauseCMSpage = lazy(() => import('../modules/cause_page'))
  const AboutCMSpage = lazy(() => import('../modules/about_page'))
  const CharityCMSpage = lazy(() => import('../modules/charity_cms'))
  const WorkspaceCMSpage = lazy(() => import('../modules/workspace_cms'))
  const HomeSlider = lazy(() => import('../modules/home_slider'))

  const Faq = lazy(() => import('../modules/faq'))
  const FaqCategory = lazy(() => import('../modules/faq_category'))
  
  const Workplace = lazy(() => import('../modules/workplace'))
  const Workplace_Member = lazy(() => import('../modules/workplace_members'))
  const Workplace_News_Cats = lazy(() => import('../modules/workplace_news_category'))
  const Workplace_News = lazy(() => import('../modules/workplace_news'))
  const WorkplaceProfile = lazy(() => import('../modules/workplace_profile'))

  

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}

        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='customers'
          element={
            <SuspensedView>
              <CustomersPage />
            </SuspensedView>
          }
        />

        <Route
          path='charitable-causes/profile'
          element={
            <SuspensedView>
              <OrgProfile />
            </SuspensedView>
          }
        />
        
        <Route
          path='charitable-causes/categories'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />

        <Route
          path='charitable-causes/organizations'
          element={
            <SuspensedView>
              <OrganizationPage />
            </SuspensedView>
          }
        />

        <Route
          path='charitable-causes/campaigns'
          element={
            <SuspensedView>
              <CausePage />
            </SuspensedView>
          }
        />

        <Route
          path='workplace/list_workplace'
          element={
            <SuspensedView>
              <Workplace />
            </SuspensedView>
          }
        />

        <Route
          path='workplace/my_workplace'
          element={
            <SuspensedView>
              <WorkplaceProfile />
            </SuspensedView>
          }
        />

        <Route
          path='workplace/list_members'
          element={
            <SuspensedView>
              <Workplace_Member />
            </SuspensedView>
          }
        />
        
        <Route
          path='workplace/news_category'
          element={
            <SuspensedView>
              <Workplace_News_Cats />
            </SuspensedView>
          }
        />
        
        <Route
          path='workplace/news'
          element={
            <SuspensedView>
              <Workplace_News />
            </SuspensedView>
          }
        />

        <Route
          path='members'
          element={
            <SuspensedView>
              <MemberPage />
            </SuspensedView>
          }
        />

        <Route
          path='avatars'
          element={
            <SuspensedView>
              <AvatarPage />
            </SuspensedView>
          }
        />

        <Route
          path='tags'
          element={
            <SuspensedView>
              <TagsPage />
            </SuspensedView>
          }
        />

        <Route
          path='loyality-rules'
          element={
            <SuspensedView>
              <LoyalityPage />
            </SuspensedView>
          }
        />

        <Route
          path='member-level'
          element={
            <SuspensedView>
              <StatusPage />
            </SuspensedView>
          }
        />

        <Route
          path='donations'
          element={
            <SuspensedView>
              <DonationPage />
            </SuspensedView>
          }
        />

        <Route
          path='communication/types'
          element={
            <SuspensedView>
              <CommunicationTypesPage />
            </SuspensedView>
          }
        />

        <Route
          path='communication/preferences'
          element={
            <SuspensedView>
              <CommunicationPreferencePage />
            </SuspensedView>
          }
        />

        <Route
          path='country'
          element={
            <SuspensedView>
              <CountryPage />
            </SuspensedView>
          }
        />

        <Route
          path='state'
          element={
            <SuspensedView>
              <StatePage />
            </SuspensedView>
          }
        />

        <Route
          path='currency'
          element={
            <SuspensedView>
              <CurrencyPage />
            </SuspensedView>
          }
        />

        <Route
          path='timezones'
          element={
            <SuspensedView>
              <TimezonePage />
            </SuspensedView>
          }
        />

        <Route
          path='cms/how_it_works'
          element={
            <SuspensedView>
              <HowItWorks />
            </SuspensedView>
          }
        />

        <Route
          path='cms/invest_in_causes'
          element={
            <SuspensedView>
              <InvestInCauses />
            </SuspensedView>
          }
        />
        
        <Route
          path='cms/our_team'
          element={
            <SuspensedView>
              <OurTeam />
            </SuspensedView>
          }
        />

        <Route
          path='cms/home'
          element={
            <SuspensedView>
              <Homepage />
            </SuspensedView>
          }
        />
        
        <Route
          path='cms/slider'
          element={
            <SuspensedView>
              <HomeSlider />
            </SuspensedView>
          }
        />

        <Route
          path='cms/cause'
          element={
            <SuspensedView>
              <CauseCMSpage />
            </SuspensedView>
          }
        />

        <Route
          path='cms/about'
          element={
            <SuspensedView>
              <AboutCMSpage />
            </SuspensedView>
          }
        />
        
        <Route
          path='cms/charity'
          element={
            <SuspensedView>
              <CharityCMSpage />
            </SuspensedView>
          }
        />
        
        <Route
          path='cms/workplace'
          element={
            <SuspensedView>
              <WorkspaceCMSpage />
            </SuspensedView>
          }
        />

        <Route
          path='pages'
          element={
            <SuspensedView>
              <CMSPages />
            </SuspensedView>
          }
        />
        
        <Route
          path='news-category'
          element={
            <SuspensedView>
              <NewsCategoryPage />
            </SuspensedView>
          }
        />

        <Route
          path='news-news'
          element={
            <SuspensedView>
              <NewsPage />
            </SuspensedView>
          }
        />
        
        <Route
          path='notifications'
          element={
            <SuspensedView>
              <Notifications />
            </SuspensedView>
          }
        />

        <Route
          path='faq/list'
          element={
            <SuspensedView>
              <Faq />
            </SuspensedView>
          }
        />

        <Route
          path='faq/category'
          element={
            <SuspensedView>
              <FaqCategory />
            </SuspensedView>
          }
        />

        <Route
          path='users'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path='change-password'
          element={
            <SuspensedView>
              <ChangePasswordPage />
            </SuspensedView>
          }
        />

        <Route
          path='logout'
          element={
            // <SuspensedView>
            <Logout />
            // </SuspensedView>
          }
        />

        <Route
          path='orgLogin/emailverify/'
          element={
            // <SuspensedView>
            <VerifyOrganization />
            // </SuspensedView>
          }
        />

        <Route
          path='workplaceLogin/EmailVerify/'
          element={
            // <SuspensedView>
            <VerifyWorkplace />
            // </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')

  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
