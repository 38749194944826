/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  const login = sessionStorage.getItem('auth')
  const loginData = JSON.parse(login!)

  // console.log('login dataaaa', loginData);

  const orgMember = loginData && loginData?.member?.orgLoginID ? true : false
  const workplaceMember = loginData && loginData?.member?.workplace?.workplaceID ? true : false

  // console.log('org mmber', orgMember);

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}
      {/* DASHBOARD */}
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Dashboard (Overview)'
        fontIcon='bi-layers'
      />

      {/* CHARITABLE ORGANIZATIONS */}
      {!orgMember && !workplaceMember ? (
        <AsideMenuItemWithSub
          to='/charitable-causes'
          title='Charitable Causes'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen022.svg'
        >
          <AsideMenuItem to='/charitable-causes/categories' title='Categories' hasBullet={true} />
          <AsideMenuItem
            to='/charitable-causes/organizations'
            title='Charity Partners'
            hasBullet={true}
          />
          <AsideMenuItem to='/charitable-causes/campaigns' title='Causes' hasBullet={true} />
        </AsideMenuItemWithSub>
      ) : (
        !workplaceMember && (
          <AsideMenuItemWithSub
            to='/charitable-causes'
            title='Charitable Causes'
            fontIcon='bi-archive'
            icon='/media/icons/duotune/general/gen022.svg'
          >
            <AsideMenuItem to='/charitable-causes/profile' title='Profile' hasBullet={true} />
            <AsideMenuItem to='/charitable-causes/campaigns' title='Campaigns' hasBullet={true} />
          </AsideMenuItemWithSub>
        )
      )}

      {/* Workplace */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItemWithSub
          to='/workplace'
          title='Workplace'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen054.svg'
        >
          <AsideMenuItem to='/workplace/list_workplace' title='List Workplace' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {workplaceMember && (
        <AsideMenuItemWithSub
          to='/workplace'
          title='Workplace'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen054.svg'
        >
          <AsideMenuItem to='/workplace/my_workplace' title='My Workplace' hasBullet={true} />
          <AsideMenuItem to='/workplace/list_members' title='List Members' hasBullet={true} />
          <AsideMenuItem to='/workplace/news' title='News' hasBullet={true} />
          <AsideMenuItem to='/workplace/news_category' title='News Category' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {/* MEMBERS */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItemWithSub
          to='/newMembers'
          title='Members'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen056.svg'
        >
          <AsideMenuItem to='/members' title='Members' hasBullet={true} />
          <AsideMenuItem to='/avatars' title='Avatars' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {/* TAGS */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItem
          to='/tags'
          title='Tags'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen050.svg'
        />
      )}

      {/* LOYALITY RULES */}
      {/* <AsideMenuItem
        to='/loyality-rules'
        title='Loyality Rules'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen029.svg'
      /> */}

      {/* MEMBER STATUS/LEVEL */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItem
          to='/member-level'
          title='Member Status/Level'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen048.svg'
        />
      )}

      {/* DONATIONS */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItem
          to='/donations'
          title='Donations'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/finance/fin001.svg'
        />
      )}

      {/* COMMUNICATIONS */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItemWithSub
          to='/communication'
          title='Communications'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen024.svg'
        >
          <AsideMenuItem to='/communication/types' title='Communication Types' hasBullet={true} />
          <AsideMenuItem
            to='/communication/preferences'
            title='Communication Preferences'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      )}
      {/* GEO LOCATION */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItemWithSub
          to='/location'
          title='GEO LOCATION'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen015.svg'
        >
          <AsideMenuItem to='/country' title='Country' hasBullet={true} />
          <AsideMenuItem to='/state' title='State' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {/* CURRENCY */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItem
          to='/currency'
          title='Currency'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/finance/fin003.svg'
        />
      )}

      {/* TIMEZONES */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItem
          to='/timezones'
          title='TimeZones'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen013.svg'
        />
      )}

      {/* CMS Pages */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItemWithSub
          to='/cms'
          title='CMS'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen017.svg'
        >
          <AsideMenuItem to='/cms/slider' title='Slider' hasBullet={true} />
          <AsideMenuItem to='/cms/home' title='Home' hasBullet={true} />
          <AsideMenuItem to='/cms/cause' title='Cause' hasBullet={true} />
          <AsideMenuItem to='/cms/about' title='About' hasBullet={true} />
          <AsideMenuItem to='/cms/charity' title='Charity' hasBullet={true} />
          <AsideMenuItem to='/cms/workplace' title='Workplace' hasBullet={true} />
          <AsideMenuItem to='/cms/how_it_works' title='How It Works' hasBullet={true} />
          <AsideMenuItem to='/cms/invest_in_causes' title='Invest In Causes' hasBullet={true} />
          <AsideMenuItem to='/cms/our_team' title='Our Team' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {/* Dynamic Pages */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItem
          to='/pages'
          title='Pages'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen028.svg'
        />
      )}

      {/* NEWS */}
      {!workplaceMember && (
        <AsideMenuItemWithSub
          to='/news'
          title='News'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen009.svg'
        >
          <AsideMenuItem to='/news-category' title='Category' hasBullet={true} />
          <AsideMenuItem to='/news-news' title='News' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {/* FAQ */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItemWithSub
          to='/faq'
          title='Faq'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen018.svg'
        >
          <AsideMenuItem to='faq/category' title='Faq Category' hasBullet={true} />
          <AsideMenuItem to='faq/list' title='Faq' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {!orgMember && !workplaceMember && (
        <AsideMenuItem
          to='/notifications'
          title='Notifications'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen005.svg'
        />
      )}

      {/* SYSTEM */}
      {!orgMember && !workplaceMember && (
        <AsideMenuItemWithSub
          to='/system'
          title='System'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen045.svg'
        >
          <AsideMenuItem to='/users' title='Users' hasBullet={true} />
          <AsideMenuItem to='/change-password' title='Change Password' hasBullet={true} />
          <AsideMenuItem to='/logout' title='Logout' hasBullet={true} />
        </AsideMenuItemWithSub>
      )}

      {(orgMember || workplaceMember) && (
        <AsideMenuItem
          to='/logout'
          title='Logout'
          fontIcon='bi-archive'
          icon='/media/icons/duotune/general/gen036.svg'
        />
      )}

      {/* <AsideMenuItem
        to='/crafted/pages/profile'
        icon='/media/icons/duotune/general/gen047.svg'
        title='About'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/crafted/pages/profile'
        icon='/media/icons/duotune/general/gen056.svg'
        title='Learn'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/crafted/pages/profile'
        icon='/media/icons/duotune/general/gen007.svg'
        title='Charity Marketplace'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/customers'
        icon='/media/icons/duotune/general/gen007.svg'
        title='Customers'
        fontIcon='bi-layers'
      />

      <AsideMenuItem
        to='/logout'
        icon='/media/icons/duotune/general/gen051.svg'
        title='Logout'
        fontIcon='bi-layers'
      /> */}

      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/general/gen019.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen022.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/general/gen040.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}
      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/general/gen025.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/communication/com012.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen051.svg'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
