import axios from 'axios'
import {useEffect} from 'react'
import GlobalURL from './global'

// const loginData = JSON.parse(sessionStorage.getItem('auth'));/
const loginData = JSON.parse(localStorage.getItem('auth'))

const token = loginData && loginData.access_token

const csrfToken = document.querySelector('meta[name="csrf-token"]')

let localhttpclient = axios.create({
  //baseURL:'http://localhost/v2.1/php/',
  baseURL: GlobalURL[0].url,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-CSRFToken, CSRF ',
    // 'CSRF': csrfToken.content,
    Authorization: 'Bearer ' + token,
  },
})

let livehttpclient = axios.create({
  baseURL: GlobalURL[0].url,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, X-CSRFToken, CSRF',
    // 'CSRF' : csrfToken.content,
    Authorization: 'Bearer ' + token,
  },
})

export const httpClient =
  window.location.hostname === 'localhost' ? localhttpclient : livehttpclient
