/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  MixedWidget1,
  FeedsWidget5,
  TablesWidget1,
  TablesWidget2,
  TablesWidget3,
  TablesWidget4,
} from '../../../_metronic/partials/widgets'
import {DashboardListCard} from './Component/DashboardListCard'
import {TableDashboard} from './Component/TableDashboard'
import { httpClient } from 'appUtility/Api'

const columns = [
  {id: 'charity', name: 'Charity'},
  {id: 'date', name: 'Date'},
  {id: 'hrs', name: 'Hours'},
]

const rows = [
  {charity: 'Red Cross Melbourne', date: '01/01/2020', hrs: '1 hour'},
  {charity: 'SoS Australia', date: '01/01/2020', hrs: '2 Hours'},
  {charity: 'Red Cross Sydney', date: '01/01/2020', hrs: '3 Hours'},
  {charity: 'Health for Life', date: '01/01/2020', hrs: '4 Hours'},
  {charity: 'Red Cross', date: '01/01/2020', hrs: '5 Hours'},
  {charity: 'Red Cross', date: '01/01/2020', hrs: '6 Hours'},
]

const favourites = [
  {
    image: '/media/stock/600x400/img-20.jpg',
    name: 'Australian Red Cross',
    hours: '125,087',
    like: '1',
    totalLikes: '25',
  },
  {
    image: '/media/stock/600x400/img-19.jpg',
    name: 'Care Flight',
    hours: '99,589',
    like: '0',
    totalLikes: '43',
  },
  {
    image: '/media/stock/600x400/img-25.jpg',
    name: 'Health Foundation',
    hours: '112,900',
    like: '0',
    totalLikes: '12',
  },
  {
    image: '/media/stock/600x400/img-24.jpg',
    name: 'SOS Foundation',
    hours: '67,679',
    like: '1',
    totalLikes: '52',
  },
  {
    image: '/media/stock/600x400/img-23.jpg',
    name: 'Live Life',
    hours: '110,190',
    like: '0',
    totalLikes: '63',
  },
]

const loginData = JSON.parse(sessionStorage.getItem('auth') || "{}");
const orgMember = (loginData && loginData?.member?.orgLoginID) ? true : false;
const workplaceID = (loginData && loginData?.member?.workplaceID);

interface DashboardProps {
  // analyticData: {
  //   causes: number | string,
  //   hours: number | string,
  //   members: number | string,
  //   orgs: number | string,
  //   totalAmount: number | string
  // }
  analyticData: any
}

const DashboardPage: FC<DashboardProps> = (props) => (

  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        {/* <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        /> */}
        <MixedWidget1
          className='card-xl-stretch mb-xl-8'
          color={orgMember ? 'success' : workplaceID != null ? 'info' : 'primary'}
          heading='Hours Donated'
          topHeader='Total Hours'
          topValue={props.analyticData.hours || '225'}
          secondHeader='Causes'
          secondValue={props.analyticData.orgs || '45'}
          thirdHeader='Campaigns'
          thirdValue={props.analyticData.causes || '62'}
          fourthHeader='Members'
          fourthValue={props.analyticData.members || '108'}
          fifthHeader='Donated Amount'
          fifthValue={(`$` + props.analyticData.totalAmount) || '$128,078'}
        />
      </div>
      <div className='col-xxl-8'>
        {/* <ListsWidget5 className='card-xxl-stretch' /> */}

        {/* Latest hours */}
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />

      </div>
      {/* <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div> */}
      {/* <div className='col-xxl-8'>
        <FeedsWidget5 className='mb-5 mb-xl-8' />
      </div> */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      
      <div className='col-xxl-4'>

        {/* News */}
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />


        {/* <TableDashboard
          heading={'Scheduled Hours'}
          rows={rows}
          columns={columns}
          className='card-xxl-stretch mb-5 mb-xl-8'
        /> */}
      </div>
      <div className='col-xl-8'>
        {/* Campaigns */}
        {workplaceID != null ? "" :
          <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
        }
        {/* <TableDashboard
          heading={'Recent Hours'}
          rows={rows}
          columns={columns}
          className='card-xxl-stretch mb-5 mb-xl-8'
        /> */}
      </div>
    </div>
    {/* end::Row */}

     {/* <div className='row gy-5 g-xl-8'>
     <div className='col-xl-4'>
        <DashboardListCard
          className='card-xl-stretch mb-xl-8'
          heading='Favourited Charities'
          favourites={favourites}
        />
      </div>
      </div> */}


    {/* begin::Row */}
    {/* <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        <DashboardListCard
          className='card-xl-stretch mb-xl-8'
          heading='Favourited Charities'
          favourites={favourites}
        />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
      </div>
    </div> */}
    {/* end::Row */}

    {/* <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div> */}
  </>
)

const DashboardWrapper: FC = () => {
  const [analyticData, setAnalyticData] = useState<object>([])
  
  useEffect(() => {
    getAnalytics()
  },[])

  const intl = useIntl()
  const getAnalytics = () => {
    httpClient.get(orgMember ? "organization/analytics" : workplaceID != null ? 'workplace-dashboard/analytics' : "/analytics").then(({data}) => {
      if(data.success){
        setAnalyticData(data.data)
      }
    })
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage analyticData={analyticData} />
    </>
  )
}

export {DashboardWrapper}
