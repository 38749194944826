/* eslint-disable jsx-a11y/anchor-is-valid */
import {createRef, useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {login} from '../redux/AuthCRUD'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import ReCAPTCHA from 'react-google-recaptcha'
import actions from '../store/actions'
import {httpClient} from '../../../../appUtility/Api'
import ToastDiv from '../../../components/toast'
import {Toast, ToastBody, ToastContainer, ToastHeader} from 'react-bootstrap'
const qs = require('qs')

const loginSchema = Yup.object().shape({
  sysAdminUserName: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  sysAdminPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  GRecaptchaResponse: Yup.string().nullable().required('Please, check the capcha'),
})

const initialValues = {
  sysAdminUserName: '',
  sysAdminPassword: '',
  GRecaptchaResponse: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

const base_url = process.env.REACT_APP_API_URL

export function WorkplaceLogin() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  // const recaptchaRef = createRef();
  const recaptchaRef = useRef(null)
  const [showToast, setShowToast] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')

  const handleToast = () => {
    setShowToast(false)
  }

  const handleCaptcha = (value) => {
    formik.setFieldValue('GRecaptchaResponse', value)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,

    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      setTimeout(() => {
        httpClient
          .post(
            'workplaceLogin',
            qs.stringify({
              email: values.sysAdminUserName,
              password: values.sysAdminPassword,
              GRecaptchaResponse: values.GRecaptchaResponse,
            })
          )
          .then(({data}) => {
            console.log('daata', data)
            if (data.success) {
              sessionStorage.setItem('auth', JSON.stringify(data.data))
              localStorage.setItem(
                'auth',
                JSON.stringify({
                  ...data.data,
                  sessionExpiryDate: new Date(Date.now() + 12 * 60 * 60 * 1000),
                })
              )
              setShowToast(true)
              setSuccess(true)
              setMessage(data.message)
              setSubmitting(false)
              setLoading(false)
              setTimeout(() => {
                window.location.pathname = '/dashboard'
              }, 1000)
            } else {
              setShowToast(true)
              setSuccess(false)
              setMessage(data.message)
              setSubmitting(false)
              setLoading(false)
            }
          })
      }, 1000)
    },
  })

  // console.log('formik', formik)

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* <input type="hidden" name="csrf-token" value="{{ csrf_token() }}" /> */}

        {/* begin::Heading */}
        {/* <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to Metronic</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div>
      </div> */}
        {/* begin::Heading */}

        {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('sysAdminUserName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.sysAdminUserName && formik.errors.sysAdminUserName},
              {
                'is-valid': formik.touched.sysAdminUserName && !formik.errors.sysAdminUserName,
              }
            )}
            type='email'
            name='sysAdminUserName'
            autoComplete='off'
          />
          {formik.touched.sysAdminUserName && formik.errors.sysAdminUserName && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{formik.errors.sysAdminUserName}</span>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}

              {/* end::Link */}
            </div>
          </div>
          <input
            type='password'
            autoComplete='off'
            {...formik.getFieldProps('sysAdminPassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.sysAdminPassword && formik.errors.sysAdminPassword,
              },
              {
                'is-valid': formik.touched.sysAdminPassword && !formik.errors.sysAdminPassword,
              }
            )}
          />
          {formik.touched.sysAdminPassword && formik.errors.sysAdminPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.sysAdminPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <ReCAPTCHA
              ref={recaptchaRef}
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              sitekey='6LfS9a4eAAAAAPSWV9H1PgAiynwrbH7rFzQ2N3n7'
              onChange={(value) => handleCaptcha(value)}
              // {...formik.getFieldProps('captcha')}
              // onChange={() => setCaptcha(recaptchaRef.current.getValue())}
            />
            {formik.touched.GRecaptchaResponse && formik.errors.GRecaptchaResponse && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.GRecaptchaResponse}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='fv-row mb-10'>
          <Link to='/auth' className='link-primary fs-6 fw-bolder' style={{marginLeft: '5px'}}>
            Sign In
          </Link>
        </div>

        <div className='fv-row mb-10'>
          <Link
            to='/auth/forgot-password'
            className='link-primary fs-6 fw-bolder'
            style={{marginLeft: '5px'}}
          >
            Forgot Password ?
          </Link>
        </div>
        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Login</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
      {showToast && (
        <ToastDiv
          handleToast={handleToast}
          showToast={showToast}
          success={success}
          message={message}
        />
      )}
    </>
  )
}
