import React, {useEffect, useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'
import {httpClient} from '../../../appUtility/Api'
import ToastDiv from '../../components/toast'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const qs = require('qs')

const initialValues = {
  sysAdminUserName: '',
  GRecaptchaResponse: '',
}

export function VerifyOrganization() {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [showToast, setShowToast] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    handleSubmit()
  }, [])

  const handleToast = () => {
    setShowToast(false)
  }

  const handleSubmit = () => {
    setLoading(true)
    httpClient.get(`orgLogin/EmailVerify${location.search}`).then(({data}) => {
      if (data.success) {
        sessionStorage.setItem('auth', JSON.stringify(data.data))
        setShowToast(true)
        setSuccess(true)
        setMessage(data.message)
        setLoading(false)
        setTimeout(() => {
          window.location.pathname = '/dashboard'
        }, 2000)
      } else {
        setLoading(false)
        setShowToast(true)
        setSuccess(false)
        setMessage(data.message)
      }
    })
  }

  return (
    <>
      <div>
        <div className='container'>
          <div className='row mt-3 mb-3'>
            <div className='col-12 mt-10 mb-12'>
            <a href='#' className=''>
              <img alt='Logo' src={toAbsoluteUrl('/final_hour/finalhour_logo.png')} className='h-45px' />
            </a>
            </div>
            <div className='col-12 mt-10 mb-3'>
              <h1>Organization Email Verification</h1>
            </div>

            <div className='col-12 mt-3 mb-3'>
              <p>{loading ? "Verifying..." : success ? "Verified!" : "Already Verified!"}</p>
            </div>            
            <div className='col-12 mt-3 mb-3'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-primary fw-bolder'
                disabled={loading}
                onClick={handleSubmit}
              >
                Verify Email
              </button>
            </div>
          </div>
        </div>
        {showToast && (
          <ToastDiv
            handleToast={handleToast}
            showToast={showToast}
            success={success}
            message={message}
          />
        )}
      </div>
    </>
  )
}
