/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, VerifyOrganization, VerifyWorkplace} from '../modules/auth'
import {RootState} from '../../setup'
import {App} from '../App'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  // const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  // const authdata = JSON.parse(sessionStorage.getItem('auth')!)
  // const isAuthorized = authdata !== null ? authdata.access_token : ''

  const authdata = JSON.parse(localStorage.getItem('auth')!)
  let isAuthorized = ''
  if (new Date().getTime() < new Date(authdata?.sessionExpiryDate).getTime()) {
    sessionStorage.setItem('auth', localStorage.getItem('auth')!) //to store session on new tab
    isAuthorized = authdata !== null ? authdata.access_token : ''
  } else {
    sessionStorage.clear()
    localStorage.clear()
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          {/* <Route path='logout' element={<Logout />} /> */}
          {isAuthorized ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboard' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='orgLogin/emailverify/' element={<VerifyOrganization />} />
              <Route path='workplaceLogin/EmailVerify/' element={<VerifyWorkplace />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
