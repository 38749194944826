/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { httpClient } from 'appUtility/Api'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {

  const loginData = JSON.parse(sessionStorage.getItem('auth') || "{}");
  const orgMember = (loginData && loginData?.member?.orgLoginID) ? true : false;
  const workplaceID = (loginData && loginData?.member?.workplaceID);

  const [tableData, setTableData]= useState<any>([])

  useEffect(() => {
    httpClient.get(orgMember ? 'organization/latest-hours': workplaceID != null ? 'workplace-dashboard/latest-hours' : `latest-hours`).then(({data}) => {
      if(data.success){
        setTableData(data.data)
      }
    })
  },[])

  const data1 = [
    {name: "Kelvin Saver", cause: "RSPC", hours: "20"},
    {name: "Ryan Holiday", cause: "RSPC Little childrens", hours: "35"},
    {name: "Mark Manson", cause: "Community Services", hours: "50"},
    {name: "Kellie Helper", cause: "Red Cross", hours: "92"},
    {name: "Bob Hardy", cause: "Medication 24", hours: "46"},
    {name: "Sara Wilson", cause: "RSPC", hours: "79"},
  ]

  const data2 = [
    {name: "Kelvin Saver", cause: "RSPC", hours: "20"},
    {name: "Ryan Holiday", cause: "RSPC Little childrens", hours: "35"},
    {name: "Mark Manson", cause: "Community Services", hours: "50"},
    {name: "Kellie Helper", cause: "Red Cross", hours: "92"},
    {name: "Sara Wilson", cause: "RSPC", hours: "79"},
  ]

  const data3 = [
    {name: "Kelvin Saver", cause: "RSPC", hours: "20"},
    {name: "Ryan Holiday", cause: "RSPC Little childrens", hours: "35"},
    {name: "Sara Wilson", cause: "RSPC", hours: "79"},
  ]

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Latest Hours</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>250 hours</span> */}
        </h3>
        {/* <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_1'
              >
                Month
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_2'
              >
                Week
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_3'
              >
                Day
              </a>
            </li>
          </ul>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    {/* <th className='p-0 w-50px'></th> */}
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                {tableData && tableData.map((data,index) => (
                  index < 6 &&
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        {data?.member?.memberName}
                      </a>
                    </td>
                    <td className='text-end text-muted fw-bold'>{data?.causeID?.causeName}</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>{data?.donationRuleID?.donationHour} hours</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                ))}
                </tbody>
                {/* <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Brad Simmons
                      </a>
                      <span className='text-muted fw-bold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>RSPC</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>20 hours</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Brian Harold
                      </a>
                    </td>
                    <td className='text-end text-muted fw-bold'>RSPC</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        New Users
                      </a>
                      <span className='text-muted fw-bold d-block'>Awesome Users</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>Laravel,Metronic</td>
                    <td className='text-end'>
                      <span className='badge badge-light-primary'>Success</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Active Customers
                      </a>
                      <span className='text-muted fw-bold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>AngularJS, C#</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Bestseller Theme
                      </a>
                      <span className='text-muted fw-bold d-block'>Best Customers</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>ReactJS, Ruby</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                </tbody> */}
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}

          {/* <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                <tbody>
                {data2.map(data => (
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        {data.name}
                      </a>
                    </td>
                    <td className='text-end text-muted fw-bold'>{data.cause}</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>{data.hours} hours</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                ))}
                </tbody>

               
              </table>
            </div>
          </div> */}


          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          
          
          {/* <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            <div className='table-responsive'>
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                <tbody>
                {data3.map(data => (
                  <tr>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        {data.name}
                      </a>
                    </td>
                    <td className='text-end text-muted fw-bold'>{data.cause}</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>{data.hours} hours</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                ))}
                </tbody>
                
              </table>
            </div>
          </div> */}
          {/* end::Tap pane */}


        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget5}
