import React from 'react'
import {ToastContainer} from 'react-bootstrap'
import {Toast} from 'react-bootstrap'

function ToastDiv({showToast, handleToast, success, message}) {
  return (
    <ToastContainer className='p-3 position-fixed' position={'top-end'}>
      <Toast
        onClose={handleToast}
        show={showToast}
        delay={3000}
        autohide
        bg={success ? 'success' : 'danger'}
      >
        {/* <Toast.Header closeButton={true}>
            <strong className='mr-auto'>Success</strong>
        </Toast.Header> */}
        <Toast.Body style={{color: '#fff'}}>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default ToastDiv
