import React, {useRef, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../redux/AuthCRUD'
import ReCAPTCHA from 'react-google-recaptcha'
import {httpClient} from '../../../../appUtility/Api'
import ToastDiv from '../../../components/toast'
const qs = require('qs')

const initialValues = {
  sysAdminUserName: '',
  GRecaptchaResponse: '',
}

const forgotPasswordSchema = Yup.object().shape({
  sysAdminUserName: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  GRecaptchaResponse: Yup.string().nullable().required('Please, check the capcha'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [showToast, setShowToast] = useState(false)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')
  const recaptchaRef = useRef(null)

  const handleToast = () => {
    setShowToast(false)
  }

  const handleCaptcha = (value) => {
    formik.setFieldValue('GRecaptchaResponse', value)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        httpClient
          .post(
            'forgot-password',
            qs.stringify({
              sysAdminUserName: values.sysAdminUserName,
              GRecaptchaResponse: values.GRecaptchaResponse,
              baseURL:
                window.location.hostname === 'localhost'
                  ? 'http://localhost:3000'
                  : 'http://backend.finalhour.org',
            })
          )
          .then(({data}) => {
            if (data.success) {
              setShowToast(true)
              setSuccess(true)
              setMessage(data.data.message)
              setLoading(false)
              // setTimeout(() => {
              //   window.location.pathname = '/login'
              // }, 2000)
            } else {
              setLoading(false)
              setShowToast(true)
              setSuccess(true)
              setMessage('Failed to Reset Password')
            }
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Sorry, looks like there are some errors detected, please try again.
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>Sent password reset. Please check your email</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('sysAdminUserName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.sysAdminUserName && formik.errors.sysAdminUserName},
              {
                'is-valid': formik.touched.sysAdminUserName && !formik.errors.sysAdminUserName,
              }
            )}
          />
          {formik.touched.sysAdminUserName && formik.errors.sysAdminUserName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.sysAdminUserName}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <ReCAPTCHA
              ref={recaptchaRef}
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              sitekey='6LfS9a4eAAAAAPSWV9H1PgAiynwrbH7rFzQ2N3n7'
              onChange={(value) => handleCaptcha(value)}
              // {...formik.getFieldProps('captcha')}
              // onChange={() => setCaptcha(recaptchaRef.current.getValue())}
            />
            {formik.touched.GRecaptchaResponse && formik.errors.GRecaptchaResponse && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.GRecaptchaResponse}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Submit</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancel
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      {showToast && (
        <ToastDiv
          handleToast={handleToast}
          showToast={showToast}
          success={success}
          message={message}
        />
      )}
    </>
  )
}
